import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import BlogpostSummary from "../components/BlogpostSummary"
import { UnstyledBlockUl } from "../components/blocks"
import Pagination, { postsPerPage } from "../components/Pagination"

export default function Home({ data }) {
  const { allSanityPost } = data
  const recentPosts = allSanityPost.nodes
  const featuredPost = recentPosts[0]

  return (
    <Layout>
      <UnstyledBlockUl mb={["48px", null, "64px"]}>
        <BlogpostSummary
          key={featuredPost?.id}
          Element="li"
          featured
          {...featuredPost}
        />
        {recentPosts.slice(1).map((post) => (
          <BlogpostSummary key={post?.id} Element="li" {...post} />
        ))}
      </UnstyledBlockUl>
      <Pagination
        currentPage={1}
        numPages={Math.ceil(allSanityPost.totalCount / postsPerPage)}
      />
    </Layout>
  )
}

export const pageData = graphql`
  query {
    allSanityPost(
      filter: {
        slug: { current: { ne: null } }
        isFuture: { eq: false }
        publishedAt: { ne: null }
      }
      limit: 15
      skip: 0
      sort: { fields: [publishedAt], order: [DESC] }
    ) {
      nodes {
        ...PostDataFragment
      }
      totalCount
    }
  }
`
